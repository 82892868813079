import React from 'react'

import TimeRangeSlider from 'react-time-range-slider';

type Props = {
    name: string,
    currentStart?: string
    currentEnd?: string
    onChange: (value: MyTimeRange) => void
}

export default class MyTimeRangeSlider extends React.PureComponent<Props>
{
    render() {
        return (
            <TimeRangeSlider
                disabled={false}
                format={24}
                minValue={"07:00"}
                maxValue={"20:00"}
                name={this.props.name}
                onChange={this.props.onChange}
                step={15}
                value={{
                    start: this.props.currentStart || "07:00",
                    end: this.props.currentEnd || "20:00"
                }}
            />
        )
    }
}