import React from 'react'

export default class Choices extends React.PureComponent {
    render() {
        return (
            <div className="row service-box-wrapper">
                {this.props.children}
            </div>
        );
    }
}