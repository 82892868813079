export function getServicePriceForTimeslotDifference(pickupTimeslotDifference: number): number {
    if (pickupTimeslotDifference < 60) {
        return 100;
    }
    else if (pickupTimeslotDifference < 120) {
        return 75;
    }
    else if (pickupTimeslotDifference < 240) {
        return 50;
    }
    else if (pickupTimeslotDifference < 480) {
        return 25;
    }
    else {
        return 0;
    }
}