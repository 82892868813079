import React from 'react';
import PropTypes from 'prop-types';
import axios, { AxiosInstance, AxiosResponse, AxiosError, AxiosRequestConfig } from "axios";
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faSpinner } from '@fortawesome/free-solid-svg-icons'

import { Steps, BookingSteps,StepLabels } from "../data/enums"
import * as Formatter from "../util/format"
import * as Pricing from "../businesslogic/pricing"
import * as Prices from "../data/prices"

import StepNavigation from './StepNavigation'
import AddressPicker from './AddressPicker'
import DatePickerInput from './DatePickerInput'
import ExtraPrice from './ExtraPrice'
import CountryDropdown from './CountryDropdown'
import MyTimeRangeSlider from "./MyTimeRangeSlider"
import PayPalCheckout from "./PayPalCheckout"
import InvoiceCheckout from "./InvoiceCheckout"
import Choices from "./Choices"
import Choice from "./Choice"
import { Benefits } from './Benefits';

declare var API_KEY_GEO: any;
declare var API_KEY_PRICING: any;

// This structure rapresent the standars Grubuer Logistics way to
// map an address
export interface GeocodeData {
    Label: string;
    HouseNumber?: string;
    Street?: string;
    PostalCode?: string;
    City?: string;
    Province?: string;
    Region?: string;
    Country?: string;
    Latitude: number;
    Longitude: number;
    Matched: boolean;
    Code?: number;
    Company?: string;
    Relevance: number;
    Shape?: any;
}

function timestringToNumber(timestring?: string): number {
    if (timestring?.length) {
        try {
            const [hours, minutes] = timestring.split(":");
            return Number.parseInt(hours) * 60 + Number.parseInt(minutes);
        }
        catch {

        }
    }
    return 0;
}

function getEarliestDateFromNow(isFlexibleDate: boolean): Date {
    let workdaysToAdd = 2;
    if (isFlexibleDate) {
        let currentHours = new Date().getHours();
        if (currentHours < 12) {
            workdaysToAdd = 0;
        }
        else {
            workdaysToAdd = 1;
        }
    }

    let date = new Date();
    let workdaysAdded = 0;
    while (workdaysAdded < workdaysToAdd) {
        date.setDate(date.getDate() + 1);
        if (date.getDay() !== 0) {
            workdaysAdded++;
        }
    }
    date.setHours(0, 0, 0, 0);
    return date;
}

function strlen(s?: string) {
    return s ? s.length : 0;
}

function postZapierHook(url: string, data: object, headers?: object) {
    let allHeaders: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            ...headers
        }
    };
    return axios.post(url, data, allHeaders);
}

type CalculatorState = {
    currentStep: Steps
    currentSubstep?: BookingSteps

    isLoading: boolean
    error?: string
    priceError?:string
    wasValidated?: boolean
    hasValidationErrors?: boolean

    basicData: BasicData
    timetableData: TimetableData
    servicesData: ServicesData
    clientData: BookingClientData
    pickupData: BookingPickupData
    deliveryData: BookingDeliveryData

    earliestDate: Date

    priceTransport: number,
    priceVehicle: number,
    pricePickupTimeslot: number,
    priceDeliveryTimeslot: number,
    priceServices: number,
    priceTax: number,
    priceTotal: number,

    summaryEmail?: string,
    repeatRequestEmail?:string

    paymentCompleted: boolean,
    paymentToken: string,
    paymentMethod?: 'paypal' | 'invoice' | 'request'
}

type Context = {
    api: AxiosInstance
}

export default class Calculator extends React.Component<{}, CalculatorState, Context>
{
    static contextTypes = {
        api: PropTypes.func
    }

    constructor(props: any) {
        super(props);

        let earliestDate = getEarliestDateFromNow(true);

        this.state = {
            currentStep: Steps.Route,
            currentSubstep: BookingSteps.Intro,

            isLoading: false,
            error: undefined,
            priceError: undefined,

            basicData: {
                vehicleType: "standard",
                pickupAddress: "",
                pickupAddressValidated: undefined,
                deliveryAddress: "",
                deliveryAddressValidated: undefined,
                cargo:"",
                industry:undefined
            },
            timetableData: {
                pickupDate: earliestDate,
                pickupType: "flexible",
                pickupTimeslotBegin: "07:00",
                pickupTimeslotEnd: "20:00",
                pickupTimeslotDifference: 780,
                deliveryTimeslotBegin: "07:00",
                deliveryTimeslotEnd: "20:00",
                deliveryTimeslotDifference: 780,
            },
            servicesData: {
                avisPickup: "email",
                avisDelivery: "email",
                tracking: "events",
                cmr: "digital",
            },
            clientData: {
                firstName: "",
                lastName: "",
                name: "",
                company: "",
                email: "",
                phone: "",
                street: "",
                zip: "",
                city: "",
                country: "",
                vat: "",
                acceptDSGVO: false,
                acceptTerms: false,
                acceptDataProcessing: false,
                acceptADR: false
            },
            pickupData: {
                firstName:"",
                lastName:"",
                addressType: "same",
                name: "",
                company: "",
                email: "",
                phone: "",
                street: "",
                zip: "",
                city: "",
                country: "",
                contactName: "",
                contactEmail: "",
                contactPhone: "",
            },
            deliveryData: {
                firstName:"",
                lastName:"",
                addressType: "same",
                name: "",
                company: "",
                email: "",
                phone: "",
                street: "",
                zip: "",
                city: "",
                country: "",
                contactName: "",
                contactEmail: "",
                contactPhone: "",
            },

            earliestDate: earliestDate,

            priceTransport: 0,
            priceVehicle: 0,
            pricePickupTimeslot: 0,
            priceDeliveryTimeslot: 0,
            priceServices: 0,
            priceTax: 0,
            priceTotal: 0,

            summaryEmail: undefined,

            paymentCompleted: false,
            paymentToken: "",
            paymentMethod: undefined
        };
    }

    retrievePrice = () => {
        this.setState({
            isLoading: true,
            error: undefined,
        });

        let d = this.state.basicData;
        let url = `/pricing/v2/Pricing?accept_max_distance=25000&departure_date=${Formatter.formatISODate(this.state.timetableData.pickupDate)}&` +
            `loadingAddress=${d.pickupAddress}&unloadingAddress=${d.deliveryAddress}`;
        this.context.api
            .get(url, {
                headers:{
                    "x-apikey": API_KEY_PRICING
                }
            })
            .then(this.handlePriceResonse)
            .catch(this.handlePriceError);
    }

    handlePriceResonse = (response: AxiosResponse) => {
        if (response.status === 200) {
            let price = response.data.price as number;

            this.trackPrice(this.state.basicData.pickupAddress, this.state.basicData.deliveryAddress, price);

            this.setState({
                priceTransport: price,
                isLoading: false,
                error: undefined,
                priceError: undefined,
                currentSubstep: BookingSteps.Intro
            });

            this.recalculatePrice({ transportPrice: price });
        }
        else {
            this.setPriceError(response.status);
        }
        this.setStep(Steps.Timetable);
        setTimeout(()=>{
            this.trackPriceEmailLead()
        },100)
    }

    async trackPriceEmailLead() {
        postZapierHook('https://hooks.zapier.com/hooks/catch/6431282/o1usysf/', this.state)
    }

    async trackPrice(fromAddress: string, toAddress: string, price: number) {
        axios
            .post( // Address Anfragen Hook
                'https://hooks.zapier.com/hooks/catch/6431282/ot01cog/',
                {
                    fromAddress,
                    toAddress,
                    price
                },
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                }
            )
            .catch(r => console.log(r));
    }

    handlePriceError = (error: AxiosError) => {
        console.log(error.message);

        let status: number | undefined;
        let message: string;

        if (error.code === "ECONNABORTED") {
            status = 0;
            message = "Zeitüberschreitung beim Warten auf den Server. Bitte versuchen Sie es erneut.";
        } else {
            status = error.response?.status;
            message = error.message;
        };

        this.setPriceError(status, message);
    }

    setPriceError(statusCode?: number, details?: string) {
        let messages: { [key: number]: string } = {
            203: "Achtung: Diese Route enthält Fährverbindungen",
            204: "Leider können wir online keinen Preis für diese Route berechnen. Vervollständigen Sie Ihre Angaben und wir lassen Ihnen innerhalb 48 Stunden ein Angebot zukommen. ",
            406: "Leider konnten wir keine Route vom Startort zum Zielort berechnen",
            500: "Ein Serverfehler ist aufgetreten"
        };
        let message = messages[statusCode ?? 0] ?? details ?? "Leider haben wir für diese Strecke keinen Preis gefunden.";

        this.setState({
            isLoading: false,
            error: statusCode !== 204 ? message : undefined,
            priceError: statusCode === 204 ? message : undefined
        });
    }

    recalculatePrice = (args: Partial<PriceCalculationOptions>) => {
        // Redux would be nice here

        function latestValue<T>(argsValue: T, stateValue: T): T {
            return argsValue || stateValue;
        }

        let transportPrice = latestValue(args.transportPrice, this.state.priceTransport) || 0;

        const reallySelected = (argValue: string | undefined, stateValue: any, selectedString: string) =>
            (argValue === selectedString) || (argValue === undefined && stateValue === selectedString);

        let servicePrice = 0;

        let vehicleAdditionalPrice = 0;
        if (reallySelected(args.vehicleType, this.state.basicData.vehicleType, "megatrailer")) {
            vehicleAdditionalPrice = transportPrice / 100 * 8;
            if (vehicleAdditionalPrice < 80) {
                vehicleAdditionalPrice = 80;
            }
            servicePrice += vehicleAdditionalPrice;
        }

        if (reallySelected(args.pickupType, this.state.timetableData.pickupType, "fix")) {
            servicePrice += Prices.FixedPickupDate;
        }

        let pickupTimeslotPrice = 0;
        let pickupTimeslotDifference = latestValue(args.pickupTimeslotDifference, this.state.timetableData.pickupTimeslotDifference);
        if (pickupTimeslotDifference !== undefined) {
            pickupTimeslotPrice = Pricing.getServicePriceForTimeslotDifference(pickupTimeslotDifference);
            servicePrice += pickupTimeslotPrice;
        }

        let deliveryTimeslotPrice = 0;
        let deliveryTimeslotDifference = latestValue(args.deliveryTimeslotDifference, this.state.timetableData.deliveryTimeslotDifference);
        if (deliveryTimeslotDifference !== undefined) {
            deliveryTimeslotPrice = Pricing.getServicePriceForTimeslotDifference(deliveryTimeslotDifference);
            servicePrice += deliveryTimeslotPrice;
        }

        if (reallySelected(args.avisPickup, this.state.servicesData.avisPickup, "phone")) {
            servicePrice += 15;
        }

        if (reallySelected(args.avisDelivery, this.state.servicesData.avisDelivery, "phone")) {
            servicePrice += 15;
        }

        if (reallySelected(args.tracking, this.state.servicesData.tracking, "gps")) {
            servicePrice += 80;
        }

        if (reallySelected(args.cmr, this.state.servicesData.cmr, "original")) {
            servicePrice += 25;
        }

        let netPrice = transportPrice + servicePrice;

        let country = args.country || this.state.clientData.country;
        let tax = country === "ITA" ? (netPrice / 100 * 22) : 0;

        let totalPrice = netPrice + tax;

        this.setState({
            priceVehicle: vehicleAdditionalPrice,
            pricePickupTimeslot: pickupTimeslotPrice,
            priceDeliveryTimeslot: deliveryTimeslotPrice,
            priceServices: servicePrice,
            priceTax: tax,
            priceTotal: totalPrice,
        });
    }

    setStep = (step: Steps | undefined) => {
        if (step !== undefined) {
            this.setState({
                currentStep: step,
                wasValidated: false,
                hasValidationErrors: false
            });
            const w:any = window

            if(w.dataLayer){
                const label = StepLabels[step]
                console.log('track step',label)
                w.dataLayer.push({
                    'event': 'step',step:label
                });
                w.dataLayer.push({
                    'event': 'Pageview',
                    'pagePath': label,
                    'pageTitle': label
                })
            }

        }
    }

    setSubstep = (step: BookingSteps) => {
        this.setState({
            currentSubstep: step,
            wasValidated: false,
            hasValidationErrors: false
        });
    }

    setVehicleType = (value: string) => {
        this.setBasicDataState("vehicleType", value);
        this.recalculatePrice({ vehicleType: value });
    }
    setCargo = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>)=>{
        const cargo = event.target.value
        this.setBasicDataState("cargo", cargo);
    }

    setIndustry = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>)=>{
        const industry = event.target.value
        this.setBasicDataState("industry", industry);
    }

    setBasicDataState<K extends keyof BasicData>(name: K, value: any) {
        this.setState((state) => {
            return {
                basicData: {
                    ...state.basicData,
                    [name]: value
                }
            }
        });
    }

    setStartAddress = (address: string, isSelected: boolean) => {
        if (address === this.state.basicData.pickupAddress) {
            return;
        }

        this.setBasicDataState("pickupAddress", address);

        if (!isSelected) {
            this.setBasicDataState("pickupAddressValidated", false);
        }
        else {
            this.setBasicDataState("pickupAddressValidated", undefined);
            this.validateAddress(address, (result) => {
                this.setState((state) => {
                    return {
                        basicData: {
                            ...state.basicData,
                            pickupAddressValidated: result.isValid,
                        },
                        pickupData: {
                            ...state.pickupData,
                            street: result.street,
                            zip: result.zip,
                            city: result.city,
                            country: result.country,
                        }
                    }
                });
            });
        }
    }

    setDestinationAddress = (address: string, isSelected: boolean) => {
        if (address === this.state.basicData.deliveryAddress) {
            return;
        }

        this.setBasicDataState("deliveryAddress", address);

        if (!isSelected) {
            this.setBasicDataState("deliveryAddressValidated", false);
        }
        else {
            this.setBasicDataState("deliveryAddressValidated", undefined);

            this.validateAddress(address, (result) => {
                this.setState((state) => {
                    return {
                        basicData: {
                            ...state.basicData,
                            deliveryAddressValidated: result.isValid,
                        },
                        deliveryData: {
                            ...state.deliveryData,
                            street: result.street,
                            zip: result.zip,
                            city: result.city,
                            country: result.country,
                        }
                    }
                });
            });
        }
    }

    validateAddress = (address: string, callback: (result: AddressValidationResult) => void) => {
        this.setState({
            error: undefined
        });
        this.context.api
            .get(`/geo/v1/geo/address?accept_max_size=25000&address=${address}`, {
                headers:{
                    "x-apikey": API_KEY_GEO
                }
            })
            .then((response: AxiosResponse) => {
                const geoData:GeocodeData[] = response.data.data;
                // The first is always the more precise if present
                let isValid: boolean = !!geoData.length && geoData[0].Matched;
                let city = "";
                let country = "";
                let zip = "";
                let street = "";

                if (isValid && geoData.length) {
                    const candidate: GeocodeData = geoData[0];
                    console.log('candidate',candidate)
                    try {
                        zip = candidate.PostalCode || "";
                        city = candidate.City || "";
                        country = candidate.Country || "";
                        street =  ((candidate.Street ?? "") + " " + (candidate.HouseNumber ?? "")).trim();
                    }
                    catch (e) {
                        console.log('validation error',e)
                        // silently ignore, use blank info
                    }
                }
                console.log('validate address',country)
                callback({
                    isValid,
                    street,
                    zip,
                    city,
                    country,
                });
            });
    }

    setClientData = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        let name = event.target.name;
        let value = event.target.type === 'checkbox' ?
            (event.target as HTMLInputElement).checked : event.target.value;

        this.setState((state) => {
            return {
                hasValidationErrors: undefined,
                clientData: {
                    ...state.clientData,
                    [name]: value
                }
            }
        });

        if (name === "country") {
            this.recalculatePrice({ [name]: value.toString() });
        }
    }

    setPickupData = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState((state) => {
            return {
                hasValidationErrors: undefined,
                pickupData: {
                    ...state.pickupData,
                    [name]: value
                }
            }
        });
    }

    setDeliveryData = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState((state) => {
            return {
                hasValidationErrors: undefined,
                deliveryData: {
                    ...state.deliveryData,
                    [name]: value
                }
            }
        });
    }

    setPickupDate = (date: Date) => {
        this.updateTimetableState({
            pickupDate: date
        });
        this.retrievePrice();
    }

    setPickupTimeslot = (value: MyTimeRange) => {
        let difference = timestringToNumber(value.end) - timestringToNumber(value.start);

        let data = {
            pickupTimeslotBegin: value.start,
            pickupTimeslotEnd: value.end,
            pickupTimeslotDifference: difference,
        };

        this.updateTimetableState(data);

        this.recalculatePrice(data);
    }

    setDeliveryTimeslot = (value: MyTimeRange) => {
        let difference = timestringToNumber(value.end) - timestringToNumber(value.start);

        let data = {
            deliveryTimeslotBegin: value.start,
            deliveryTimeslotEnd: value.end,
            deliveryTimeslotDifference: difference,
        };

        this.updateTimetableState(data);

        this.recalculatePrice(data);
    }

    setTimetableData = (event: React.ChangeEvent<HTMLInputElement>) => {
        let name = event.target.name;
        let value = event.target.value;

        this.updateTimetableState({ [name]: value });
        this.recalculatePrice({ [name]: value });
    }

    setPickupType(value: "flexible" | "fix"): void {
        this.updateTimetableState({ pickupType: value });

        let earliestDate = getEarliestDateFromNow(value === "flexible");
        this.setState({
            earliestDate: earliestDate
        });

        if (this.state.timetableData.pickupDate < earliestDate) {
            this.updateTimetableState({ pickupDate: earliestDate });
        }

        this.recalculatePrice({ pickupType: value });
    }

    updateTimetableState(updatedPart: Partial<TimetableData>) {
        this.setState((state) => {
            let updatedData = {
                ...state.timetableData,
                ...updatedPart
            };

            // console.debug(updatedData);

            return {
                timetableData: updatedData
            }
        });
    }

    setServicesData = (name: string, value: string) => {
        this.setState((state) => {
            return {
                servicesData: {
                    ...state.servicesData,
                    [name]: value
                }
            }
        });

        this.recalculatePrice({ [name]: value });
    }

    setSummaryEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ summaryEmail: event.target.value });
    }

    setRepeatRequestEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ repeatRequestEmail: event.target.value });
    }



    render() {
        return (
            <div className="row">
                <div className="col-sm-8">
                    <div className="card">
                        <div className="card-body">
                            {this.state.paymentCompleted ? this.renderProcessCompleted() : this.renderCustomerForm()}
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <Benefits />
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    {this.renderInfos()}
                    {(this.state.currentStep === Steps.Price) && this.renderReapatOrderBox()}
                    {(this.state.currentStep === Steps.Timetable || this.state.currentStep === Steps.Services) && this.renderComebackLater()}
                </div>
            </div>
        );
    }

    renderStateDump() {
        return (
            <div className="row">
                <div className="col">
                    <pre>
                        {JSON.stringify(this.state, null, 2)}
                    </pre>
                </div>
            </div>
        );
    }

    getNavLinkClassName(step: Steps) {
        let isCurrent = step === this.state.currentStep;
        let isEnabled = this.isNavStepEnabled(step);
        return classnames({
            "current": isCurrent,
            "disabled": !isEnabled
        });
    }

    isNavStepEnabled(step: Steps) {
        if (step === Steps.Route) {
            return true;
        }
        else if (this.state.currentStep === Steps.Route) {
            // we need to force a pricing update
            return false;
        }
        else if (step === Steps.Timetable) {
            return this.isRouteDataComplete();
        }
        else if (step === Steps.Services) {
            return this.isRouteDataComplete();
        }
        else if (step === Steps.Booking) {
            return this.isRouteDataComplete();
        }
        else if (step === Steps.Payment) {
            return this.isRouteDataComplete() && this.isTimetableDataComplete() && this.isServicesDataComplete() && this.isBookingDataComplete();
        }
    }

    isObjectComplete(d: object, nullableProperties: string[]) {
        for (let [key, value] of Object.entries(d)) {
            if (!nullableProperties.includes(key)) {
                if (value === undefined) {
                    // console.warn(`Validation error: property ${key} is undefined`);
                    return false;
                }
                if (value === "") {
                    // console.warn(`Validation error: property ${key} is empty`);
                    return false;
                }
                if (value === false) {
                    // console.warn(`Validation error: property ${key} is false`);
                    return false;
                }
            }
        }
        return true;
    }

    isDataComplete() {
        return this.isRouteDataComplete() && this.isTimetableDataComplete() && this.isServicesDataComplete() && this.isBookingDataComplete();
    }

    isRouteDataComplete():boolean {
        let d = this.state.basicData;
        return d.vehicleType !== undefined &&
            strlen(d.pickupAddress) > 0 &&
            strlen(d.deliveryAddress) > 0 &&
            strlen(d.industry) > 0  &&
            strlen(d.cargo) > 0 &&
            this.state.clientData.acceptADR ? true : false
    }

    isPriceDataComplete() {
        let d = this.state.clientData;
        return d.firstName && d.lastName && this.isSummaryEmailValid() && d.acceptDSGVO ? true : false
    }

    isTimetableDataComplete() {
        let d = this.state.timetableData;
        return (d.pickupType === "fix" || d.pickupType === "flexible") &&
            d.pickupDate && d.pickupDate >= this.state.earliestDate &&
            timestringToNumber(d.pickupTimeslotBegin) >= timestringToNumber("07:00") &&
            timestringToNumber(d.pickupTimeslotEnd) <= timestringToNumber("20:00") &&
            timestringToNumber(d.deliveryTimeslotBegin) >= timestringToNumber("07:00") &&
            timestringToNumber(d.deliveryTimeslotEnd) <= timestringToNumber("20:00");
    }

    isServicesDataComplete() {
        let d = this.state.servicesData;
        return this.isObjectComplete(d, []);
    }

    isBookingDataComplete() {
        return this.isClientDataComplete() && this.isPickupDataComplete() && this.isDeliveryDataComplete();
    }

    isClientDataComplete() {
        let d = this.state.clientData;
        return this.isObjectComplete(d, ['addressAddition', 'comment', 'invoiceReference']);
    }

    isPickupDataComplete() {
        let d = this.state.pickupData;
        if (d.addressType === undefined) {
            return false;
        }
        else if (d.addressType === "same") {
            return this.isObjectComplete({
                street: d.street,
                zip: d.zip
            }, []);
        }
        else {
            return this.isObjectComplete(d, ['addressAddition', 'pickupReference']);
        }
    }

    isDeliveryDataComplete() {
        let d = this.state.deliveryData;
        if (d.addressType === undefined) {
            return false;
        }
        else if (d.addressType === "same") {
            return this.isObjectComplete({
                street: d.street,
                zip: d.zip
            }, []);
        }
        else {
            return this.isObjectComplete(d, ['addressAddition', 'deliveryReference']);
        }
    }

    isSummaryEmailValid() {
        let value = this.state.summaryEmail ?? "";
        if (value.length === 0) {
            return false;
        }
        else {
            return /\S+@\S+\.\S+/.test(value);
        }
    }

    isEmailValid(value?:string) {
        if(!value) return false
        if (value.length === 0) {
            return false;
        }
        else {
            return /\S+@\S+\.\S+/.test(value);
        }
    }

    renderCustomerForm() {
        let classes = classnames(
            "form-horizontal", "form-wizard-wrapper", "wizard", "needs-validation",
            { "was-validated": this.state.wasValidated === true }
        );

        return (
            <div>
                <form
                    id="form-horizontal"
                    ref="form"
                    className={classes}
                    role="application"
                >
                    <div key="steps" className="steps clearfix">
                        <ul className="" role="tablist">
                            <li
                                key="route"
                                role="tab"
                                className={this.getNavLinkClassName(Steps.Route)}
                            >
                                <a
                                    onClick={this.isNavStepEnabled(Steps.Route) ? () => this.setStep(Steps.Route) : undefined}>
                                    <span className="number">1</span>
                                    Route
                                </a>
                            </li>
                            <li
                                key="price"
                                role="tab"
                                className={this.getNavLinkClassName(Steps.Price)}
                            >
                                <a
                                    onClick={this.isNavStepEnabled(Steps.Price) ? () => this.setStep(Steps.Price) : undefined}>
                                    <span className="number">2</span>
                                    Preis
                                </a>
                            </li>
                            <li
                                key="timetable"
                                role="tab"
                                className={this.getNavLinkClassName(Steps.Timetable)}
                            >
                                <a
                                    onClick={this.isNavStepEnabled(Steps.Timetable) ? () => this.setStep(Steps.Timetable) : undefined}>
                                    <span className="number">3</span>
                                    Zeitplan
                                </a>
                            </li>
                            <li
                                key="services"
                                role="tab"
                                className={this.getNavLinkClassName(Steps.Services)}
                            >
                                <a
                                    onClick={this.isNavStepEnabled(Steps.Services) ? () => this.setStep(Steps.Services) : undefined}>
                                    <span className="number">4</span>
                                    Services
                                </a>
                            </li>
                            <li
                                key="booking"
                                role="tab"
                                className={this.getNavLinkClassName(Steps.Booking)}
                            >
                                <a
                                    onClick={this.isNavStepEnabled(Steps.Booking) ? () => this.setStep(Steps.Booking) : undefined}>
                                    <span className="number">5</span>
                                    Zusammenfassung
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div key="data" id="customerdata">
                        {this.state.currentStep === Steps.Route && this.renderRouteForm()}
                        {this.state.currentStep === Steps.Price && this.renderPriceForm()}
                        {this.state.currentStep === Steps.Timetable && this.renderTimetableForm()}
                        {this.state.currentStep === Steps.Services && this.renderServicesForm()}
                        {this.state.currentStep === Steps.Booking && this.renderBookingForm()}
                        {this.state.currentStep === Steps.Payment && this.renderPaymentForm()}
                        {this.state.currentStep === Steps.Completed && this.renderProcessCompleted()}
                    </div>
                </form>
                {this.state.error && (
                    <div className="alert alert-danger">
                        <p>{this.state.error}</p>
                        <p>Rufen Sie unsere Kundenbetreuer an, wir kümmern uns gerne um Ihr Anliegen:<br />Tel: +49 89 5123 3028 (MO – FR von 09:00 - 17:00 Uhr)</p>
                    </div>
                )}
            </div>
        );
    }

    renderRouteForm(): React.ReactNode {
        return (
            <div id="route" key="route">
                <h3>Route</h3>
                <div className="form-group">
                    <div className="row">
                        <div className="col">
                            <label htmlFor="vehicleType">Fahrzeug</label>
                        </div>
                    </div>
                    <Choices>
                        <Choice
                            name="vehicle"
                            value="standard"
                            isSelected={this.state.basicData.vehicleType === "standard"}
                            onSelect={() => this.setVehicleType("standard")}
                        >
                            <h6 className="title1 m-0">FTL Standard Tautliner</h6>
                            <p className="text-muted mb-0">L*B*H: 13,60*2,45*2,55m<br />Zuladegewicht: 25 Tonnen</p>
                        </Choice>
                        <Choice
                            name="vehicle"
                            value="megatrailer"
                            isSelected={this.state.basicData.vehicleType === "megatrailer"}
                            onSelect={() => this.setVehicleType("megatrailer")}
                        >
                            <h6 className="title1 m-0">FTL Megatrailer <span className="badge badge-success mt-0">+8% (mind. 80 EUR)</span></h6>
                            <p className="text-muted mb-0">L*B*H: 13,60*2,45*2,95m<br />Zuladegewicht: 25 Tonnen</p>
                        </Choice>
                    </Choices>
                    <div className="form-group row">
                        <div className="col">
                            <label htmlFor="pickupAddress">Start Adresse</label>
                            <AddressPicker
                                id="pickupAddress"
                                value={this.state.basicData.pickupAddress}
                                isValidValue={this.state.basicData.pickupAddressValidated}
                                onCommitValue={this.setStartAddress}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col">
                            <label htmlFor="deliveryAddress">Ziel Adresse</label>
                            <AddressPicker
                                id="deliveryAddress"
                                value={this.state.basicData.deliveryAddress}
                                isValidValue={this.state.basicData.deliveryAddressValidated}
                                onCommitValue={this.setDestinationAddress}
                            />
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col">
                                <label htmlFor="industry">Was möchten sie liefern*</label>

                                <input
                                    className="form-control"
                                    type="text"
                                    name="cargo"
                                    onChange={this.setCargo}
                                    value={this.state.basicData.cargo}
                                    disabled={false}
                                />
                        </div>
                        <div className="col">
                                <label htmlFor="pickupAddress">Ihre Industrie*</label>
                                <div className="select form-control">
                                    <select value={this.state.basicData.industry} onChange={this.setIndustry}>
                                        <option>Bitte wählen</option>
                                        <option>Automobilindustrie</option>
                                        <option>Maschinenbau</option>
                                        <option>Elektroindustrie</option>
                                        <option>Plastikindustrie</option>
                                        <option>Pharmazeutische Industrie</option>
                                        <option>Eisen und Stahl Industrie</option>
                                        <option>Chemische Industrie</option>
                                        <option>Lebensmittelindustrie</option>
                                        <option>Getränkeindustrie</option>
                                        <option>Sonstiges Industrie</option>
                                    </select>
                                    <span className="arrow">▾</span>
                                </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col">

                                <input

                                    type="checkbox"
                                    name="acceptADR"
                                    onChange={this.setClientData}
                                    checked={this.state.clientData.acceptADR ? true :false}
                                    disabled={false}
                                />
                                {' '}
                                Kein Gefahrengut (ADR)*


                        </div>

                    </div>
                    {this.state.isLoading ? (
                        <div className="text-right">
                            Ihr Preis wird berechnet <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                        </div>
                    ) : (
                            <StepNavigation
                                previousStep={undefined}
                                isPreviousEnabled={false}
                                nextStep={Steps.Price}
                                isNextEnabled={this.isRouteDataComplete()}
                                //onChangeStep={this.onFinishRouteForm}
                                onChangeStep={this.setStep}
                            />
                        )}
                </div>
            </div>
        );
    }

    renderPriceForm = () =>{
        return <div id="price" key="price">
        <h3>Wohin dürfen wir hr Angebot senden?</h3>
        <div className="form-group">
            <h2>Wohin dürfen wir Ihr Angebot senden?</h2>
            <div className="form-group row">
                <div className="col">
                    <label htmlFor="pickupAddress">Vorname*</label>
                    <input
                        required
                        className="form-control"
                        type="text"
                        name="firstName"
                        onChange={this.setClientData}
                        value={this.state.clientData.firstName}
                        disabled={false}
                    />
                </div>
                <div className="col">
                    <label htmlFor="pickupAddress">Nachname*</label>
                    <input
                        required
                        className="form-control"
                        type="text"
                        name="lastName"
                        //value={this.state.timetableData.deliveryTimeslotEnd}
                        disabled={false}
                        onChange={this.setClientData}
                        value={this.state.clientData.lastName}
                    />
                </div>
            </div>
            <div className="form-group row">
                <div className="col">
                    <label htmlFor="pickupAddress">E-Mail*</label>
                    <input
                        required
                        className="form-control"
                        type="text"
                        name="email"
                        //value={this.state.timetableData.deliveryTimeslotEnd}
                        disabled={false}
                        value={this.state.summaryEmail}
                        onChange={this.setSummaryEmail}
                    />
                </div>
                <div className="col">
                    <label htmlFor="pickupAddress">Telefonnummer (Inkl. Vorwahl)</label>
                    <input
                        className="form-control"
                        type="text"
                        name="phone"
                        //value={this.state.timetableData.deliveryTimeslotEnd}
                        disabled={false}
                        onChange={this.setClientData}
                        value={this.state.clientData.phone}
                    />
                </div>
            </div>
            <div className="row">
                <small>
                    <input
                        name="acceptDSGVO"
                        type="checkbox"
                        checked={this.state.clientData.acceptDSGVO}
                        onChange={this.setClientData}>
                    </input>{' '}Ihre Daten werden SSL-verschlüsselt übertragen und ausschließlich zur Angebotsstellung verwendet.<br/>Ich habe die <a href="https://www.freits.eu/data-privacy ">Datenschutzbestimmungen</a> laut Gesetz Art. 13 der DSGVO 2016/679 gelesen und akzeptiert.</small>
            </div>

            {this.state.isLoading ? (
                <div className="text-right">
                    Ihr Preis wird berechnet <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                </div>
            ) : (
                    <StepNavigation
                        previousStep={undefined}
                        isPreviousEnabled={false}
                        nextStep={Steps.Timetable}
                        nextLabel="Zum Sofortpreis"
                        isNextEnabled={this.isPriceDataComplete()}
                        onChangeStep={this.onFinishRouteForm}
                    />
                )}
        </div>
    </div>
    }

    onFinishRouteForm = () => {
        if (this.state.basicData.pickupAddressValidated && this.state.basicData.deliveryAddressValidated) {
            this.retrievePrice();
        }else {
            this.setState({ error: "Die angegebene Route ist leider zu ungenau. Bitte geben Sie für die Start- und Zieladresse folgende Daten an: Straße, Hausnummer, Ort. " });
        }
    }

    renderTimetableForm(): React.ReactNode {
        return (
            <div id="timetable" key="timetable">
                <h3>Zeitplan</h3>
                <div className="form-group">
                    <div className="row">
                        <div className="col">
                            <h5>Abholoption</h5>
                            <p className="text-muted">Sie erhalten innerhalb der nächsten 60 Minuten Ihren voraussichtlichen Abholtermin.</p>
                        </div>
                    </div>
                    <Choices>
                        <Choice
                            name="pickupType"
                            value="flexible"
                            isSelected={this.state.timetableData.pickupType === "flexible"}
                            onSelect={() => this.setPickupType("flexible")}
                        >
                            <h6 className="title1 m-0">Innerhalb von 1-5 Werktagen <ExtraPrice value={0} /></h6>
                        </Choice>
                        <Choice
                            name="pickupType"
                            value="fix"
                            isSelected={this.state.timetableData.pickupType === "fix"}
                            onSelect={() => this.setPickupType("fix")}
                        >
                            <h6 className="title1 m-0">Fixiertes Abholdatum <ExtraPrice value={Prices.FixedPickupDate} /></h6>
                        </Choice>
                    </Choices>
                </div>
                <div className="form-group">
                    <h5>Abholbereit ab</h5>
                    <p className="text-muted">Bitte geben Sie an, ab welchem Datum Ihre Lieferung abholbereit ist.</p>
                    <p><small>Frühestmögliches Datum: {Formatter.formatReadableDate(this.state.earliestDate)}</small></p>
                    <DatePickerInput
                        id="pickupDate"
                        selectedDate={this.state.timetableData.pickupDate || this.state.earliestDate}
                        earliestDate={this.state.earliestDate}
                        isDisabledDate={this.isDisabledDay}
                        onSelectDate={this.setPickupDate}
                    />
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col">
                            <h5>Ankunftszeit Beladung</h5>
                            <p className="text-muted">2 Stunden Ladezeit inklusive</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label htmlFor="pickupTimeslotBegin">von</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><FontAwesomeIcon icon={faClock} /></div>
                                </div>
                                <input
                                    className="form-control"
                                    type="text" name="pickupTimeslotBegin"
                                    value={this.state.timetableData.pickupTimeslotBegin}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        <div className="col">
                            <label htmlFor="pickupTimeslotEnd">bis</label>
                            <input
                                className="form-control"
                                type="text" name="pickupTimeslotEnd"
                                value={this.state.timetableData.pickupTimeslotEnd}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col p-3 ml-2 mr-2">
                            <MyTimeRangeSlider
                                name={"pickupTimeslot"}
                                onChange={this.setPickupTimeslot}
                                currentStart={this.state.timetableData.pickupTimeslotBegin}
                                currentEnd={this.state.timetableData.pickupTimeslotEnd}
                            />
                        </div>
                    </div>
                    {this.renderPossibleExtraPriceForTimeslot(this.state.timetableData.pickupTimeslotDifference)}
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col">
                            <h5>Ankunftszeit Entladung</h5>
                            <p className="text-muted">Alle Transporte erfolgen auf direktem Weg in 1-3 Werktagen nach Abholung</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label htmlFor="deliveryTimeslotBegin">von</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><FontAwesomeIcon icon={faClock} /></div>
                                </div>
                                <input
                                    className="form-control"
                                    type="text" name="deliveryTimeslotBegin"
                                    value={this.state.timetableData.deliveryTimeslotBegin}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        <div className="col">
                            <label htmlFor="deliveryTimeslotEnd">bis</label>
                            <input
                                className="form-control"
                                type="text" name="deliveryTimeslotEnd"
                                value={this.state.timetableData.deliveryTimeslotEnd}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col p-3 ml-2 mr-2">
                            <MyTimeRangeSlider
                                name={"deliveryTimeslot"}
                                onChange={this.setDeliveryTimeslot}
                                currentStart={this.state.timetableData.deliveryTimeslotBegin}
                                currentEnd={this.state.timetableData.deliveryTimeslotEnd}
                            />
                        </div>
                    </div>
                    {this.renderPossibleExtraPriceForTimeslot(this.state.timetableData.deliveryTimeslotDifference)}
                </div>
                <StepNavigation
                    previousStep={Steps.Route}
                    isPreviousEnabled={true}
                    nextStep={Steps.Services}
                    isNextEnabled={true}
                    onChangeStep={this.setStep}
                />
            </div>
        );
    }

    renderPossibleExtraPriceForTimeslot(difference: number | undefined): React.ReactNode {
        if (difference === undefined) {
            return null;
        }
        else {
            let extraPrice = Pricing.getServicePriceForTimeslotDifference(difference);
            if (extraPrice > 0) {
                return (
                    <div className="row">
                        <div className="col p-3 ml-2 mr-2">
                            <p>Der Aufpreis für das gewählte Zeitfenster beträgt <ExtraPrice value={extraPrice} /></p>
                        </div>
                    </div>
                );
            }
            else {
                return null;
            }
        }
    }

    isDisabledDay = (day: Date): boolean => {
        if (day < this.state.earliestDate)
            return true;
        else if (day.getDay() === 0)
            return true;
        else
            return false;
    }

    renderServicesForm(): React.ReactNode {
        return (
            <div id="services" key="services">
                <h3>Zusatzleistungen</h3>
                <div className="form-group">
                    <h5>Avisierung Beladung</h5>
                    <Choices>
                        <Choice
                            name="avisPickup"
                            value="email"
                            isSelected={this.state.servicesData.avisPickup === "email"}
                            onSelect={this.setServicesData}
                        >
                            <h6 className="title1 m-0">Avis per E-Mail <ExtraPrice value={0} /></h6>
                        </Choice>
                        <Choice
                            name="avisPickup"
                            value="phone"
                            isSelected={this.state.servicesData.avisPickup === "phone"}
                            onSelect={this.setServicesData}
                        >
                            <h6 className="title1 m-0">Telefonisches Avis <ExtraPrice value={15} /></h6>
                        </Choice>
                    </Choices>
                </div>
                <div className="form-group">
                    <h5>Avisierung Entladung</h5>
                    <Choices>
                        <Choice
                            name="avisDelivery"
                            value="email"
                            isSelected={this.state.servicesData.avisDelivery === "email"}
                            onSelect={this.setServicesData}
                        >
                            <h6 className="title1 m-0">Avis per E-Mail <ExtraPrice value={0} /></h6>
                        </Choice>
                        <Choice
                            name="avisDelivery"
                            value="phone"
                            isSelected={this.state.servicesData.avisDelivery === "phone"}
                            onSelect={this.setServicesData}
                        >
                            <h6 className="title1 m-0">Telefonisches Avis <ExtraPrice value={15} /></h6>
                        </Choice>
                    </Choices>
                </div>
                <div className="form-group">
                    <h5>Tracking</h5>
                    <Choices>
                        <Choice
                            name="tracking"
                            value="events"
                            isSelected={this.state.servicesData.tracking === "events"}
                            onSelect={this.setServicesData}
                        >
                            <h6 className="title1 m-0">Event-based Tracking <ExtraPrice value={0} /></h6>
                        </Choice>
                        <Choice
                            name="tracking"
                            value="gps"
                            isSelected={this.state.servicesData.tracking === "gps"}
                            onSelect={this.setServicesData}
                        >
                            <h6 className="title1 m-0">GPS Live-Tracking <ExtraPrice value={80} /></h6>
                        </Choice>
                    </Choices>
                </div>
                <div className="form-group">
                    <h5>CMR Frachtpapiere</h5>
                    <Choices>
                        <Choice
                            name="cmr"
                            value="digital"
                            isSelected={this.state.servicesData.cmr === "digital"}
                            onSelect={this.setServicesData}
                        >
                            <h6 className="title1 m-0">Digital per E-Mail <ExtraPrice value={0} /></h6>
                        </Choice>
                        <Choice
                            name="cmr"
                            value="original"
                            isSelected={this.state.servicesData.cmr === "original"}
                            onSelect={this.setServicesData}
                        >
                            <h6 className="title1 m-0">Original per Post <ExtraPrice value={25} /></h6>
                        </Choice>
                    </Choices>
                </div>
                <StepNavigation
                    previousStep={Steps.Timetable}
                    isPreviousEnabled={true}
                    nextStep={Steps.Booking}
                    isNextEnabled={true}
                    onChangeStep={this.setStep}
                />
            </div>
        );
    }

    renderBookingForm(): React.ReactNode {
        return (
            <div id="booking" key="booking">
                <h3>Buchung</h3>
                {this.state.currentSubstep !== BookingSteps.Intro && this.renderBookingNavigation()}
                <div key="form" className="substep">
                    {this.state.currentSubstep === BookingSteps.Intro && this.renderBookingIntroForm()}
                    {this.state.currentSubstep === BookingSteps.Client && this.renderBookingClientForm()}
                    {this.state.currentSubstep === BookingSteps.Pickup && this.renderBookingPickupForm()}
                    {this.state.currentSubstep === BookingSteps.Delivery && this.renderBookingDeliveryForm()}
                </div>
            </div>
        );
    }

    renderBookingNavigation() {
        const classes = (substep: BookingSteps) => "nav-link" + (substep === this.state.currentSubstep ? " active" : "");

        return (
            <ul key="navigation" className="nav nav-pills nav-justified" role="tablist">
                <li key="client" className="nav-item waves-effect waves-light">
                    <a data-toggle="tab" role="tab"
                        className={classes(BookingSteps.Client)}
                        onClick={() => this.setSubstep(BookingSteps.Client)}>
                        4.1 Auftraggeber
                        </a>
                </li>
                <li key="pickup" className="nav-item waves-effect waves-light">
                    <a data-toggle="tab" role="tab"
                        className={classes(BookingSteps.Pickup)}
                        onClick={() => this.setSubstep(BookingSteps.Pickup)}>
                        4.2 Beladestelle
                        </a>
                </li>
                <li key="delivery" className="nav-item waves-effect waves-light">
                    <a data-toggle="tab" role="tab"
                        className={classes(BookingSteps.Delivery)}
                        onClick={() => this.setSubstep(BookingSteps.Delivery)}>
                        4.3 Entladestelle
                        </a>
                </li>
            </ul>

        );
    }

    renderBookingIntroForm(): React.ReactNode {
        return (
            <div key="intro" id="booking-intro">
                <Choices>
                    <Choice
                        name="booking"
                        value="now"
                        isSelected={false}
                    >
                        <div className="icon"></div>
                        <h6 className="title1">Wow. Weiter zur Bestellung!</h6>
                        <p>Bestätigen Sie nochmals Ihre Daten und vervollständigen Sie Ihre Adresse. In 3 Minuten sind Sie mit Ihrer Buchung fertig!</p>
                        <button className="btn button-inverted mt-1" onClick={() => this.setSubstep(BookingSteps.Client)}>Weiter</button>
                    </Choice>
                    <Choice
                        name="booking"
                        value="later"
                        isSelected={false}
                    >
                        <div className="icon"></div>
                        <h6 className="title1">Gerade nicht genügend Zeit?</h6>
                        <p>Wir schicken Ihnen eine Zusammenfassung Ihrer Anfrage. Nehmen Sie sich Zeit für die Entscheidung, wir freuen uns auf Ihre Rückmeldung.</p>
                        {this.renderSummaryEmailInput()}
                    </Choice>
                </Choices>
                <div className="mt-3"></div>
                <StepNavigation
                    previousStep={Steps.Services}
                    isPreviousEnabled={true}
                    nextStep={Steps.Booking}
                    isNextEnabled={this.isRouteDataComplete()}
                    onChangeStep={(step: Steps | undefined, isPrevious: boolean, isNext: boolean) => {
                        if (isPrevious) {
                            this.setStep(Steps.Services);
                        }
                        else if (isNext) {
                            this.setSubstep(BookingSteps.Client);
                        }
                    }}
                />
            </div>
        );
    }

    renderBookingClientForm(): React.ReactNode {
        return (
            <div key="client" id="booking-client">
                <h4>Auftraggeber</h4>
                <div key="name" className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                        className="form-control"
                        type="text" name="name"
                        defaultValue={this.state.clientData.name}
                        onBlur={this.setClientData}
                        required={true}
                    />
                </div>
                <div key="company" className="form-group">
                    <label htmlFor="company">Firma/Unternehmen</label>
                    <input
                        className="form-control"
                        type="text" name="company"
                        defaultValue={this.state.clientData.company}
                        onBlur={this.setClientData}
                        required={true}
                    />
                </div>
                <div key="email" className="form-group">
                    <label htmlFor="email">E-Mail</label>
                    <input
                        className="form-control"
                        type="text" name="email"
                        defaultValue={this.state.clientData.email}
                        onBlur={this.setClientData}
                        required={true}
                        inputMode="email"
                    />
                </div>
                <div key="phone" className="form-group">
                    <label htmlFor="phone">Telefonnummer</label>
                    <input
                        className="form-control"
                        type="text" name="phone"
                        defaultValue={this.state.clientData.phone}
                        onBlur={this.setClientData}
                        required={true}
                    />
                </div>
                <div key="vat" className="form-group">
                    <label htmlFor="vat">VAT</label>
                    <input
                        className="form-control"
                        type="text" name="vat"
                        defaultValue={this.state.clientData.vat}
                        onBlur={this.setClientData}
                        required={true}
                    />
                </div>
                <div key="street" className="form-group">
                    <label htmlFor="street">Straße</label>
                    <input
                        className="form-control"
                        type="text" name="street"
                        defaultValue={this.state.clientData.street}
                        onBlur={this.setClientData}
                        required={true}
                    />
                </div>
                <div key="zip" className="form-group">
                    <label htmlFor="zip">PLZ</label>
                    <input
                        className="form-control"
                        type="text" name="zip"
                        defaultValue={this.state.clientData.zip}
                        onBlur={this.setClientData}
                        required={true}
                    />
                </div>
                <div key="city" className="form-group">
                    <label htmlFor="city">Ort</label>
                    <input
                        className="form-control"
                        type="text" name="city"
                        defaultValue={this.state.clientData.city}
                        onBlur={this.setClientData}
                        required={true}
                    />
                </div>
                <div key="country" className="form-group">
                    <label htmlFor="country">Land</label>
                    <CountryDropdown
                        name="country"
                        value={this.state.clientData.country}
                        onChange={this.setClientData}
                        required={true}
                    />
                </div>
                <div key="addressAddition" className="form-group">
                    <label htmlFor="addressAddition">Adresszusatz (optional)</label>
                    <input
                        className="form-control"
                        type="text" name="addressAddition"
                        defaultValue={this.state.clientData.addressAddition}
                        onBlur={this.setClientData}
                    />
                </div>
                <div key="comment" className="form-group">
                    <label htmlFor="comment">Kommentar (optional)</label>
                    <textarea
                        className="form-control"
                        name="comment"
                        defaultValue={this.state.clientData.comment}
                        onChange={this.setClientData}
                    />
                </div>
                <div key="invoiceReference" className="form-group">
                    <label htmlFor="invoiceReference">Verrechnungsreferenz (optional)</label>
                    <input
                        className="form-control"
                        type="text" name="invoiceReference"
                        defaultValue={this.state.clientData.invoiceReference}
                        onBlur={this.setClientData}
                    />
                </div>
                <div key="accept" className="form-group">
                    <div key="terms" className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="acceptTerms"
                            checked={this.state.clientData.acceptTerms === true}
                            onChange={this.setClientData}
                            required={true}
                        />
                        <label className="form-check-label" htmlFor="acceptTerms">
                            Hiermit bestätige ich, dass ich die <a className="text-decoration-underline" href="http://www.freits.eu/agbs" target="_blank">Geschäftsbedingungen</a> durchgelesen habe und akzeptiere.
                        </label>
                    </div>
                    <div key="dataprocessing" className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="acceptDataProcessing"
                            checked={this.state.clientData.acceptDataProcessing === true}
                            onChange={this.setClientData}
                            required={true}
                        />
                        <label className="form-check-label" htmlFor="acceptDataProcessing">
                            <a className="text-decoration-underline" href="http://www.freits.eu/data-privacy" target="_blank">Datenschutzbestimmungen</a> laut Gesetz Art. 13 der DSGVO 2016/679 gelesen und akzeptiert
                        </label>
                    </div>
                    <div key="adr" className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="acceptADR"
                            checked={this.state.clientData.acceptADR === true}
                            onChange={this.setClientData}
                            required={true}
                        />
                        <label className="form-check-label" htmlFor="acceptADR">
                            Kein Gefahrengut (ADR)
                        </label>
                    </div>
                </div>
                {this.state.hasValidationErrors && this.renderValidationErrors()}
                <StepNavigation
                    previousStep={Steps.Services}
                    isPreviousEnabled={true}
                    nextStep={Steps.Booking}
                    isNextEnabled={this.isRouteDataComplete()}
                    onChangeStep={(step: Steps | undefined, isPrevious: boolean, isNext: boolean) => {
                        if (isPrevious) {
                            this.setStep(Steps.Services);
                        }
                        else if (isNext) {
                            let isValid = this.checkFormValidity(this.isClientDataComplete());
                            if (isValid) {
                                this.setSubstep(BookingSteps.Pickup);
                            }
                        }
                    }}
                />
            </div>
        );
    }

    renderBookingPickupForm(): React.ReactNode {
        return (
            <div key="pickup" id="booking-pickup">
                <h4>Beladestelle</h4>
                <div key="addressType" className="form-group">
                    <div key="same" className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="addressType"
                            value="same"
                            checked={this.state.pickupData.addressType === "same"}
                            onChange={this.setPickupData}
                        />
                        <label className="form-check-label">Gleich Auftraggeber</label>
                    </div>
                    <div key="alternative" className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="addressType"
                            value="alternative"
                            checked={this.state.pickupData.addressType === "alternative"}
                            onChange={this.setPickupData}
                        />
                        <label className="form-check-label">Alternative Beladestelle</label>
                    </div>
                </div>
                {this.renderBookingPickupDetails()}
                {this.state.hasValidationErrors && this.renderValidationErrors()}
                <StepNavigation
                    previousStep={Steps.Booking}
                    isPreviousEnabled={true}
                    nextStep={Steps.Booking}
                    isNextEnabled={this.isRouteDataComplete()}
                    onChangeStep={(step: Steps | undefined, isPrevious: boolean, isNext: boolean) => {
                        if (isPrevious) {
                            this.setSubstep(BookingSteps.Client);
                        }
                        else if (isNext) {
                            let isValid = this.checkFormValidity(this.isPickupDataComplete());
                            if (isValid) {
                                this.setSubstep(BookingSteps.Delivery);
                            }
                        }
                    }}
                />
            </div>
        );
    }

    renderBookingPickupDetails() {
        let addressOnly = this.state.pickupData.addressType === "same";

        return (
            <div key="pickupDetails" className="details">
                {addressOnly || (
                    <>
                        <div key="name" className="form-group">
                            <label htmlFor="name">Name</label>
                            <input
                                className="form-control"
                                type="text" name="name"
                                defaultValue={this.state.pickupData.name}
                                onBlur={this.setPickupData}
                                required={true}
                            />
                        </div>
                        <div key="company" className="form-group">
                            <label htmlFor="company">Firma/Unternehmen</label>
                            <input
                                className="form-control"
                                type="text" name="company"
                                defaultValue={this.state.pickupData.company}
                                onBlur={this.setPickupData}
                                required={true}
                            />
                        </div>
                        <div key="email" className="form-group">
                            <label htmlFor="email">E-Mail</label>
                            <input
                                className="form-control"
                                type="text" name="email"
                                defaultValue={this.state.pickupData.email}
                                onBlur={this.setPickupData}
                                required={true}
                                inputMode="email"
                            />
                        </div>
                        <div key="phone" className="form-group">
                            <label htmlFor="phone">Telefonnummer</label>
                            <input
                                className="form-control"
                                type="text" name="phone"
                                defaultValue={this.state.pickupData.phone}
                                onBlur={this.setPickupData}
                                required={true}
                            />
                        </div>
                    </>
                )}
                <div key="street" className="form-group">
                    <label htmlFor="street">Straße</label>
                    <input
                        className="form-control"
                        type="text" name="street"
                        defaultValue={this.state.pickupData.street}
                        onBlur={this.setPickupData}
                        readOnly={false}
                        required={true}
                    />
                </div>
                <div key="zip" className="form-group">
                    <label htmlFor="zip">PLZ</label>
                    <input
                        className="form-control"
                        type="text" name="zip"
                        defaultValue={this.state.pickupData.zip}
                        onBlur={this.setPickupData}
                        readOnly={false}
                        required={true}
                    />
                </div>
                <div key="city" className="form-group">
                    <label htmlFor="city">Ort</label>
                    <input
                        className="form-control"
                        type="text" name="city"
                        defaultValue={this.state.pickupData.city}
                        readOnly={true}
                    />
                </div>
                <div key="country" className="form-group">
                    <label htmlFor="country">Land</label>
                    <CountryDropdown
                        name="country"
                        value={this.state.pickupData.country}
                        readOnly={true}
                    />
                </div>
                {addressOnly || (
                    <>
                        <div key="addressAddition" className="form-group">
                            <label htmlFor="addressAddition">Adresszusatz (optional)</label>
                            <input
                                className="form-control"
                                type="text" name="addressAddition"
                                defaultValue={this.state.pickupData.addressAddition}
                                onBlur={this.setPickupData}
                            />
                        </div>
                        <div key="pickupReference" className="form-group">
                            <label htmlFor="pickupReference">Beladereferenz (optional)</label>
                            <input
                                className="form-control"
                                type="text" name="pickupReference"
                                defaultValue={this.state.pickupData.pickupReference}
                                onBlur={this.setPickupData}
                            />
                        </div>
                        <div key="contact" className="row">
                            <div className="col">
                                <h5>Kontakt Beladung</h5>
                            </div>
                        </div>
                        <div key="contactName" className="form-group">
                            <label htmlFor="contactName">Name</label>
                            <input
                                className="form-control"
                                type="text" name="contactName"
                                defaultValue={this.state.pickupData.contactName}
                                onBlur={this.setPickupData}
                                required={true}
                            />
                        </div>
                        <div key="contactEmail" className="form-group">
                            <label htmlFor="contactEmail">Email</label>
                            <input
                                className="form-control"
                                type="text" name="contactEmail"
                                defaultValue={this.state.pickupData.contactEmail}
                                onBlur={this.setPickupData}
                                required={true}
                                inputMode="email"
                            />
                        </div>
                        <div key="contactPhone" className="form-group">
                            <label htmlFor="contactPhone">Telefonnummer</label>
                            <input
                                className="form-control"
                                type="text" name="contactPhone"
                                defaultValue={this.state.pickupData.contactPhone}
                                onBlur={this.setPickupData}
                                required={true}
                            />
                        </div>
                    </>
                )}
            </div>
        );
    }

    renderBookingDeliveryForm(): React.ReactNode {
        return (
            <div key="delivery" id="booking-delivery">
                <h4>Entladestelle</h4>
                <div key="addressType" className="form-group">
                    <div key="same" className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="addressType"
                            value="same"
                            checked={this.state.deliveryData.addressType === "same"}
                            onChange={this.setDeliveryData}
                        />
                        <label className="form-check-label">Gleich Auftraggeber</label>
                    </div>
                    <div key="alternative" className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="addressType"
                            value="alternative"
                            checked={this.state.deliveryData.addressType === "alternative"}
                            onChange={this.setDeliveryData}
                        />
                        <label className="form-check-label">Alternative Entladestelle</label>
                    </div>
                </div>
                {this.renderBookingDeliveryDetails()}
                {this.state.hasValidationErrors && this.renderValidationErrors()}
                <StepNavigation
                    previousStep={Steps.Booking}
                    isPreviousEnabled={true}
                    nextStep={Steps.Booking}
                    isNextEnabled={this.isRouteDataComplete()}
                    nextLabel={this.state.priceError ? "Anfrage absenden" : "Weiter zur Buchung"}
                    onChangeStep={(step: Steps | undefined, isPrevious: boolean, isNext: boolean) => {
                        if (isPrevious) {
                            this.setSubstep(BookingSteps.Pickup);
                        }
                        else if (isNext) {
                            let isValid = this.checkFormValidity(this.isDeliveryDataComplete());
                            if(this.state.priceError){
                                return this.onPaymentCompleted('request')
                            }
                            if (isValid) {
                                this.setStep(Steps.Payment)
                            }
                        }
                    }}
                />
            </div>
        );
    }

    checkFormValidity(isCurrentDataComplete: boolean) {
        if (isCurrentDataComplete) {
            return true;
        }
        else {
            let form = this.refs["form"] as HTMLFormElement;
            let isValid = form.checkValidity();
            this.setState({
                wasValidated: true,
                hasValidationErrors: !isValid
            });
            return isValid;
        }
    }

    renderValidationErrors(): React.ReactNode {
        return (
            <div key="validationErrors" className="alert alert-warning mt-5 alert-dismissible fade show" role="alert">
                <p>Bitte vervollständigen Sie die fehlenden Pflichtfelder</p>
            </div>
        );
    }

    renderBookingDeliveryDetails() {
        let addressOnly = this.state.deliveryData.addressType === "same";

        return (
            <div key="deliveryDetails" className="details">
                {addressOnly || (
                    <>
                        <div key="name" className="form-group">
                            <label htmlFor="name">Name</label>
                            <input
                                className="form-control"
                                type="text" name="name"
                                defaultValue={this.state.deliveryData.name}
                                onBlur={this.setDeliveryData}
                                required={true}
                            />
                        </div>
                        <div key="company" className="form-group">
                            <label htmlFor="company">Firma/Unternehmen</label>
                            <input
                                className="form-control"
                                type="text" name="company"
                                defaultValue={this.state.deliveryData.company}
                                onBlur={this.setDeliveryData}
                                required={true}
                            />
                        </div>
                        <div key="email" className="form-group">
                            <label htmlFor="email">E-Mail</label>
                            <input
                                className="form-control"
                                type="text" name="email"
                                defaultValue={this.state.deliveryData.email}
                                onBlur={this.setDeliveryData}
                                required={true}
                                inputMode="email"
                            />
                        </div>
                        <div key="phone" className="form-group">
                            <label htmlFor="phone">Telefonnummer</label>
                            <input
                                className="form-control"
                                type="text" name="phone"
                                defaultValue={this.state.deliveryData.phone}
                                onBlur={this.setDeliveryData}
                                required={true}
                            />
                        </div>
                    </>
                )}
                <div key="street" className="form-group">
                    <label htmlFor="street">Straße</label>
                    <input
                        className="form-control"
                        type="text" name="street"
                        defaultValue={this.state.deliveryData.street}
                        onBlur={this.setDeliveryData}
                        readOnly={false}
                        required={true}
                    />
                </div>
                <div key="zip" className="form-group">
                    <label htmlFor="zip">PLZ</label>
                    <input
                        className="form-control"
                        type="text" name="zip"
                        defaultValue={this.state.deliveryData.zip}
                        onBlur={this.setDeliveryData}
                        readOnly={false}
                        required={true}
                    />
                </div>
                <div key="city" className="form-group">
                    <label htmlFor="city">Ort</label>
                    <input
                        className="form-control"
                        type="text" name="city"
                        defaultValue={this.state.deliveryData.city}
                        readOnly={true}
                    />
                </div>
                <div key="country" className="form-group">
                    <label htmlFor="country">Land</label>
                    <CountryDropdown
                        name="country"
                        value={this.state.deliveryData.country}
                        readOnly={true}
                    />
                </div>
                {addressOnly || (
                    <>
                        <div key="addressAddition" className="form-group">
                            <label htmlFor="addressAddition">Adresszusatz (optional)</label>
                            <input
                                className="form-control"
                                type="text" name="addressAddition"
                                defaultValue={this.state.deliveryData.addressAddition}
                                onBlur={this.setDeliveryData}
                            />
                        </div>
                        <div key="deliveryReference" className="form-group">
                            <label htmlFor="deliveryReference">Zustellungsreferenz (optional)</label>
                            <input
                                className="form-control"
                                type="text" name="deliveryReference"
                                defaultValue={this.state.deliveryData.deliveryReference}
                                onBlur={this.setDeliveryData}
                            />
                        </div>
                        <div key="contact" className="row">
                            <div className="col">
                                <h5>Kontakt Entladung</h5>
                            </div>
                        </div>
                        <div key="contactName" className="form-group">
                            <label htmlFor="contactName">Name</label>
                            <input
                                className="form-control"
                                type="text" name="contactName"
                                defaultValue={this.state.deliveryData.contactName}
                                onBlur={this.setDeliveryData}
                                required={true}
                            />
                        </div>
                        <div key="contactEmail" className="form-group">
                            <label htmlFor="contactEmail">Email</label>
                            <input
                                className="form-control"
                                type="text" name="contactEmail"
                                defaultValue={this.state.deliveryData.contactEmail}
                                onBlur={this.setDeliveryData}
                                required={true}
                                inputMode="email"
                            />
                        </div>
                        <div key="contactPhone" className="form-group">
                            <label htmlFor="contactPhone">Telefonnummer</label>
                            <input
                                className="form-control"
                                type="text" name="contactPhone"
                                defaultValue={this.state.deliveryData.contactPhone}
                                onBlur={this.setDeliveryData}
                                required={true}
                            />
                        </div>
                    </>
                )}
            </div>
        );
    }

    renderInfos(): React.ReactNode {
        return (
            <div className="card">
                <div className="card-body price-box">
                    <h4 className="header-title mt-0 mb-3">Ihr Transport</h4>
                    {this.state.currentStep === Steps.Route && this.renderBasicInfo()}
                    {this.state.currentStep > Steps.Route && this.renderTransportInfo()}
                    {this.state.currentStep > Steps.Price && this.renderPrice()}
                </div>
            </div>
        );
    }

    renderBasicInfo(): React.ReactNode {
        return (
            <div className="info-section" id="basic-info">
                <ul>
                    <li>Track &amp; Trace</li>
                    <li>Kundensupport</li>
                    <li>Sofortpreis</li>
                    <li>ISO-zertifiert</li>
                </ul>
            </div>
        );
    }

    renderTransportInfo() {
        return (
            <div className="table-responsive shopping-cart">
                <table className="table mb-0">
                    <tbody>
                        {this.renderRouteInfo()}
                        {this.renderTimetableInfo()}
                        {this.renderServicesInfo()}
                    </tbody>
                </table>
            </div>
        );
    }

    renderRouteInfo(): React.ReactNode {
        if (this.state.currentStep <= Steps.Route) {
            return null;
        }
        else {
            return (
                <>
                    <tr key="vehicle">
                        <td key="label">
                            <p className="d-inline-block align-middle mb-0 product-name">Fahrzeug</p>
                        </td>
                        <td key="value">
                            {this.state.basicData.vehicleType === "standard" && "FTL Standard Tautliner"}
                            {this.state.basicData.vehicleType === "megatrailer" && "FTL Megatrailer"}
                            {this.state.priceVehicle > 0 && (
                                <span className="details"> <ExtraPrice value={this.state.priceVehicle} /></span>
                            )}
                        </td>
                    </tr>
                    <tr key="pickup">
                        <td key="label">
                            <p className="d-inline-block align-middle mb-0 product-name">Start</p>
                        </td>
                        <td key="value">
                            {this.state.basicData.pickupAddress}
                        </td>
                    </tr>
                    <tr key="delivery">
                        <td key="label">
                            <p className="d-inline-block align-middle mb-0 product-name">Ziel</p>
                        </td>
                        <td key="value">
                            {this.state.basicData.deliveryAddress}
                        </td>
                    </tr>
                </>
            );
        }
    }

    renderTimetableInfo(): React.ReactNode {
        if (this.state.currentStep <= Steps.Timetable) {
            return null;
        }
        else {
            return (
                <>
                    <tr key="date">
                        <td key="label">
                            <p className="d-inline-block align-middle mb-0 product-name">Abholbereit ab</p>
                        </td>
                        <td key="value">
                            {Formatter.formatReadableDate(this.state.timetableData.pickupDate)}
                            {this.state.timetableData.pickupType === "flexible" && (
                                <span className="details"> (Innerhalb von 1-5 Werktagen)</span>
                            )}
                            {this.state.timetableData.pickupType === "fix" && (
                                <span className="details"> (Fixiert) <ExtraPrice value={Prices.FixedPickupDate} /></span>
                            )}
                        </td>
                    </tr>
                    <tr key="pickupTimeslot">
                        <td key="label">
                            <p className="d-inline-block align-middle mb-0 product-name">Abholzeit Beladung</p>
                        </td>
                        <td key="value">
                            <span>{this.state.timetableData.pickupTimeslotBegin} - {this.state.timetableData.pickupTimeslotEnd}</span>
                            {this.state.pricePickupTimeslot > 0 && (
                                <span>&nbsp;<ExtraPrice value={this.state.pricePickupTimeslot} /></span>
                            )}
                        </td>
                    </tr>
                    <tr key="deliveryTimeslot">
                        <td key="label">
                            <p className="d-inline-block align-middle mb-0 product-name">Ankunftszeit Entladung</p>
                        </td>
                        <td key="value">
                            <span>{this.state.timetableData.deliveryTimeslotBegin} - {this.state.timetableData.deliveryTimeslotEnd}</span>
                            {this.state.priceDeliveryTimeslot > 0 && (
                                <span>&nbsp;<ExtraPrice value={this.state.priceDeliveryTimeslot} /></span>
                            )}
                        </td>
                    </tr>
                </>
            );
        }
    }

    renderServicesInfo(): React.ReactNode {
        if (this.state.currentStep <= Steps.Services) {
            return null;
        }
        else {
            return (
                <tr key="services">
                    <td>
                        <p className="d-inline-block align-middle mb-0 product-name">Services</p>
                    </td>
                    <td>
                        {this.state.pricePickupTimeslot > 0 && (
                            <div>Zeitfenster Beladung <ExtraPrice value={this.state.pricePickupTimeslot} /></div>
                        )}
                        {this.state.priceDeliveryTimeslot > 0 && (
                            <div>Zeitfenster Entladung <ExtraPrice value={this.state.priceDeliveryTimeslot} /></div>
                        )}
                        {this.state.servicesData.avisPickup === "phone" && (
                            <div>Tel. Avisio Beladung <ExtraPrice value={15} /></div>
                        )}
                        {this.state.servicesData.avisDelivery === "phone" && (
                            <div>Tel. Avisio Entladung <ExtraPrice value={15} /></div>
                        )}
                        {this.state.servicesData.tracking === "gps" && (
                            <div>GPS Live Tracking <ExtraPrice value={80} /></div>
                        )}
                        {this.state.servicesData.cmr === "original" && (
                            <div>CMR per Post <ExtraPrice value={25} /></div>
                        )}
                    </td>
                </tr>
            );
        }
    }

    renderPrice(): React.ReactNode {

        const {priceError} = this.state

        if(priceError){
            return <div className="total-payment">
                <h4 className="header-title mt-0 mb-3">Ihr garantierter Preis</h4>
                <p>Leider können wir online keinen Preis für diese Route berechnen. Vervollständigen Sie Ihre Angaben und wir lassen Ihnen innerhalb 48 Stunden ein Angebot zukommen.</p>
            </div>
        }

        return (
            <div className="total-payment">
                <h4 className="header-title mt-0 mb-3">Ihr garantierter Preis</h4>
                <table className="table mb-0">
                    <tbody>
                        <tr>
                            <td className="payment-title">Transport</td>
                            <td>€ {Formatter.formatReadablePrice(this.state.priceTransport)}</td>
                        </tr>
                        <tr>
                            <td className="payment-title">Services</td>
                            <td>
                                € {Formatter.formatReadablePrice(this.state.priceServices)}
                            </td>
                        </tr>
                        {this.state.priceTax > 0 && (
                            <tr>
                                <td className="payment-title">MwSt</td>
                                <td>€ {Formatter.formatReadablePrice(this.state.priceTax)}</td>
                            </tr>
                        )}
                        <tr>
                            <td className="payment-title">Total</td>
                            <td className="text-dark"><strong>€ {Formatter.formatReadablePrice(this.state.priceTotal)}</strong></td>
                        </tr>
                    </tbody>
                </table>
                {this.state.clientData.country !== "IT" && (
                    <p>Alle Preise ohne MwSt</p>
                )}
                <div className="transport-by">
                    <span>Ihr Transport wird durchgeführt von</span>
                    <img alt="logo" src="/static/images/Gruber-logistics-logo-color.png" />
                </div>
            </div>
        );
    }

    renderReapatOrderBox(): React.ReactNode {

        return (
            <div key="later" className="card">
                <div className="card-body comebacklater-box comebacklater-box-purple">
                    <h4 className="mt-0 mb-3">Sie wollen mehrere Sendungen regelmäßig über freits abwickeln?</h4>
                    <p>Senden sie uns Ihre Anfrage zu und wir senden Ihnen ein maßgeschneidertes Angebot.</p>
                     {this.renderRepeatRequestSummary()}
                </div>
            </div>
        );
    }

    renderComebackLater(): React.ReactNode {

        return (
            <div key="later" className="card">
                <div className="card-body comebacklater-box">
                    <h4 className="mt-0 mb-3">Gerade nicht genügend Zeit?</h4>
                    <p>Wir schicken Ihnen eine Zusammenfassung Ihrer Anfrage. <br/> Wir freuen uns auf ihre Rückmeldung</p>
                     {this.renderSummaryEmailInput()}
                </div>
            </div>
        );
    }

    renderSummaryEmailInput(): React.ReactNode {
        let isDefined = (this.state.summaryEmail?.length ?? 0) > 0;
        let isValid = this.isSummaryEmailValid();

        let classes = classnames(
            "form-control",
            "mb-1",
            {
                "is-valid": isDefined && isValid === true,
                "is-invalid": isDefined && isValid === false
            }
        );

        return (
            <div>
                <input
                    className={classes}
                    type="text"
                    size={20}
                    name="summaryEmail"
                    placeholder="E-Mail"
                    value={this.state.summaryEmail}
                    onChange={this.setSummaryEmail}>
                </input>
                <button
                    className="btn button-inverted"
                    onClick={this.onClickSendSummary}
                    disabled={!isValid}
                >
                    Senden
                </button>
            </div>
        );
    }

    renderRepeatRequestSummary(): React.ReactNode {
        let isDefined = (this.state.repeatRequestEmail?.length ?? 0) > 0;
        let isValid = this.isEmailValid(this.state.repeatRequestEmail)

        let classes = classnames(
            "form-control",
            "mb-1",
            {
                "is-valid": isDefined && isValid === true,
                "is-invalid": isDefined && isValid === false
            }
        );

        return (
            <div>
                <input
                    className={classes}
                    type="text"
                    size={20}
                    name="repeatRequestEmail"
                    placeholder="E-Mail"
                    value={this.state.repeatRequestEmail}
                    onChange={this.setRepeatRequestEmail}>
                </input>
                <button
                    className="btn button-inverted"
                    onClick={this.onClickSendRepeatRequest}
                    disabled={!isValid}
                >
                    Senden
                </button>
            </div>
        );
    }

    renderPaymentForm(): React.ReactNode {
        return (
            <div key="payment">
                <h2>Bezahlung {this.state.paymentMethod === 'invoice' ? 'auf Rechnung' : ''}</h2>
                {this.renderPaymentContent()}
                <StepNavigation
                    previousStep={Steps.Booking}
                    isPreviousEnabled={true}
                    nextStep={undefined}
                    nextLabel={this.state.paymentMethod === 'invoice' ? 'Buchung abschließen' : undefined}
                    isNextEnabled={this.state.paymentMethod === 'invoice'}
                    onChangeStep={(step: Steps | undefined, isPrevious: boolean, isNext: boolean) => {
                        if (isPrevious) {
                            this.setStep(Steps.Booking);
                        }else if(this.state.paymentMethod === 'invoice'){
                            this.onPaymentCompleted('invoice')
                        }
                    }}
                />
            </div>
        );
    }

    renderPaymentContent() {
        if (this.state.priceTotal === 0) {
            return (
                <div key="pricingError" className="alert alert-danger">
                    <p>Der Gesamtpreis beträgt aktuell 0 Euro. Bitte überprüfen Sie die Route.</p>
                </div>
            );
        }else if (!this.isDataComplete()) {
            return (
                <div key="dataIncompleteWarnings" className="alert alert-warning">
                    <p>Die Daten sind unvollständig. Bitte ergänzen Sie die Daten in folgenden Schritten:</p>
                    <ul>
                        {this.isRouteDataComplete() || <li>Route</li>}
                        {this.isTimetableDataComplete() || <li>Zeitplan</li>}
                        {this.isServicesDataComplete() || <li>Services</li>}
                        {this.isClientDataComplete() || <li>Auftraggeber</li>}
                        {this.isPickupDataComplete() || <li>Beladestelle</li>}
                        {this.isDeliveryDataComplete() || <li>Entladestelle</li>}
                    </ul>
                </div>
            );
        }else if(this.state.paymentMethod === 'invoice'){
            return this.renderInvoiceForm()
        }else {
            return (
                <div key="checkout">
                    <p>Bitte wählen Sie die gewünschte Zahlungsart aus</p>
                    <InvoiceCheckout onSelect={()=>this.setState({paymentMethod:'invoice'})} />
                    <PayPalCheckout
                        amount={this.state.priceTotal}
                        onTransactionCompleted={this.onPaymentCompleted}
                        onTransactionFailed={this.onPaymentFailed}
                    />


                </div>
            );
        }
    }

    renderInvoiceForm(){
        return <div className="form-group">
        <h2>Wohin dürfen wir Ihre Rechnung senden?</h2>
        <div className="form-group row">
            <div className="col">
                <label htmlFor="pickupAddress">Vorname*</label>
                <input
                    required
                    className="form-control"
                    type="text"
                    name="firstName"
                    onChange={this.setClientData}
                    value={this.state.clientData.firstName}
                    disabled={false}
                />
            </div>
            <div className="col">
                <label htmlFor="pickupAddress">Nachname*</label>
                <input
                    required
                    className="form-control"
                    type="text"
                    name="lastName"
                    //value={this.state.timetableData.deliveryTimeslotEnd}
                    disabled={false}
                    onChange={this.setClientData}
                    value={this.state.clientData.lastName}
                />
            </div>
        </div>
        <div className="form-group row">
            <div className="col">
                <label htmlFor="pickupAddress">Firmensitz*</label>
                <input
                    required
                    className="form-control"
                    type="text"
                    name="companyAddress"
                    onChange={this.setClientData}
                    value={this.state.clientData.company}
                    disabled={false}
                />
            </div>
            <div className="col">
                <label htmlFor="pickupAddress">VAT NR*</label>
                <input
                    required
                    className="form-control"
                    type="text"
                    name="vat"
                    //value={this.state.timetableData.deliveryTimeslotEnd}
                    disabled={false}
                    onChange={this.setClientData}
                    value={this.state.clientData.vat}
                />
            </div>
        </div>
        <div className="form-group row">
            <div className="col">
                <label htmlFor="pickupAddress">E-Mail*</label>
                <input
                    required
                    className="form-control"
                    type="text"
                    name="email"
                    //value={this.state.timetableData.deliveryTimeslotEnd}
                    disabled={false}
                    value={this.state.summaryEmail}
                    onChange={this.setSummaryEmail}
                />
            </div>
            <div className="col">
                <label htmlFor="pickupAddress">Telefonnummer</label>
                <input
                    className="form-control"
                    type="text"
                    name="phone"
                    //value={this.state.timetableData.deliveryTimeslotEnd}
                    disabled={false}
                    onChange={this.setClientData}
                    value={this.state.clientData.phone}
                />
            </div>
            {/* <button onClick={()=>this.onPaymentCompleted}>Buchung Abschließen</button> */}
        </div>
        <div>
            Wir nehmen uns das Recht im Zuge einer negativen Kreditprüfung den Transport innerhalb von 24 Stunden abzulehnen.
        </div>
    </div>

    }
    onPaymentCompleted = (token: string) => {
        let update = {
            paymentCompleted: true,
            paymentToken: token
        };

        let latestState = {
            ...this.state,
            ...update
        }

        axios
            .post( // Freits internal email hook
                'https://hooks.zapier.com/hooks/catch/6431282/otcav1k/',
                latestState,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                }
            )
            .then(r => console.log(r))
            .catch(r => console.log(r));

        this.setState(update);
        this.setStep(Steps.Completed);
    }

    onPaymentFailed = (error: any) => {
        console.log(error);
        alert(error);
    }

    onClickSendSummary = (e?: React.BaseSyntheticEvent,silent?:boolean) => {
        if(e){
            e.preventDefault();
        }
        if (!this.isSummaryEmailValid()) {
            return;
        }
        // Hoook Email Lead
        postZapierHook('https://hooks.zapier.com/hooks/catch/6431282/ohkpvqr/', this.state)
            .then(r => {
                if(!silent) this.setStep(Steps.Completed);
            })
            .catch(r => console.log(r));
    }

    onClickSendRepeatRequest = (e: React.BaseSyntheticEvent) => {
        //e.preventDefault();

        // TODO: repeat request

        // if (!this.isSummaryEmailValid()) {
        //     return;
        // }

        // postZapierHook('https://hooks.zapier.com/hooks/catch/6431282/ohkpvqr/', this.state)
        //     .then(r => {
        //         this.setStep(Steps.Completed);
        //     })
        //     .catch(r => console.log(r));
    }

    renderProcessCompleted(): React.ReactNode {
        if(this.state.paymentToken === 'request'){
            return (<div key="processCompleted" className="col-lg-8">
            <div className="alert alert-success" role="alert">
                <p>Wir haben Ihnen gerade ein E-Mail mit allen Informationen geschickt. Wir freuen uns auf Ihre Buchung.</p>
            </div>
        </div>)
        }else if (this.state.paymentCompleted ) {
            return (
                <div key="processCompleted" className="col-lg-8">
                    <h2>Auftrag abgeschlossen</h2>
                    <div className="alert alert-success" role="alert">
                        <p>Vielen Dank für Ihren Auftrag!</p>

                        {this.state.paymentMethod !== 'invoice' ? <p>Die Bezahlung wurde erfolgreich durchgeführt. Ihre Zahlungsreferenz lautet: {this.state.paymentToken}</p> : null}

                        <p>Wir melden uns innerhalb der nächsten 2 Stunden unserer Öffnungszeiten bei Ihnen. Sie erhalten eine Zusammenfassung über Ihren Auftrag in Kürze per Email.</p>
                    </div>
                </div>
            );
        }
        else if ((this.state.summaryEmail?.length ?? 0) > 0) {
            return (
                <div key="processCompleted" className="col-lg-8">
                    <div className="alert alert-success" role="alert">
                        <p>Wir haben Ihnen gerade ein E-Mail mit allen Informationen geschickt. Wir freuen uns auf Ihre Buchung.</p>
                    </div>
                </div>
            );
        }
    }
}
