import React from 'react'
import countries from '../data/countries'

type Props = {
    name: string
    value?: string
    onChange?: React.ChangeEventHandler<HTMLSelectElement>
    readOnly?: boolean
    required?: boolean
};

export default class CountryDropdown extends React.PureComponent<Props>
{
    render() {
        return (
            <select
                className="form-control"
                name={this.props.name}
                value={this.props.value}
                onChange={this.props.onChange}
                disabled={this.props.readOnly}
                required={this.props.required}
            >
                <option key="empty" value=""></option>
                {countries.map(([code, name]) => <option key={code} value={code}>{name}</option>)}
            </select>
        );
    }
}