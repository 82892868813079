import React from 'react'
import DayPicker from 'react-day-picker';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'

import * as Formatter from "../util/format"

import 'react-day-picker/lib/style.css';

type Props = {
    id: string
    selectedDate?: Date
    earliestDate?: Date
    isDisabledDate: (date: Date) => boolean
    onSelectDate: (date: Date) => void
}

type State = {
    isOpened: boolean
}

export default class DatePickerInput extends React.PureComponent<Props, State>
{
    constructor(props: Props) {
        super(props);

        this.state = {
            isOpened: false
        }
    }

    setPickerOpened = () => {
        this.setState({ isOpened: true });
    }

    setPickerClosed = (e: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ isOpened: false });
        e.stopPropagation();
        e.preventDefault();
    }

    render() {
        return (
            <div key="datepicker" className="" onClick={this.setPickerOpened}>
                <div key="display" className='input-group'>
                    <div className="input-group-prepend">
                        <div className="input-group-text"><FontAwesomeIcon icon={faCalendar} /></div>
                    </div>
                    <input
                        key="date"
                        id={this.props.id}
                        name={this.props.id}
                        type="text"
                        className="form-control"
                        value={Formatter.formatReadableDate(this.props.selectedDate)}
                        disabled={false}
                        readOnly={true}
                        onClick={this.setPickerOpened}
                    />
                </div>
                {this.state.isOpened && this.renderPicker()}
            </div>
        );
    }

    renderPicker() {
        return (
            <div key="picker" className="card">
                <div className="card-body">
                    <button type="button" className="close" aria-label="Schließen" onClick={this.setPickerClosed}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <DayPicker
                        firstDayOfWeek={1}
                        month={this.props.earliestDate}
                        selectedDays={this.props.selectedDate}
                        disabledDays={this.props.isDisabledDate}
                        onDayClick={(day) => this.props.onSelectDate(day)}
                        showWeekNumbers={true}
                    />
                </div>
            </div>
        );
    }
}