import React,{FC} from 'react'


const Benfit:FC<{icon:string, title:string}> = ({icon,title})=>{
    return <div className="benefit">
        <img src={icon} alt={title} />
        <span>{title}</span>
    </div>
}

export const Benefits = ()=>{

    return <div className="benefits">
        <Benfit
            icon="/static/images/Flotte.png"
            title="Hohe Verfügbarkeit durch unsere große Flotte"
        />
        <Benfit
            icon="/static/images/Sofortpreis.png"
            title="Unschlagbarer Sofortpreis"
        />
        <Benfit
            icon="/static/images/Kundensupport.png"
            title="Verlässlicher Kundensupport"
        />
         <Benfit
            icon="/static/images/Track-Trace.png"
            title="Online Track & Trace"
        />
         <Benfit
            icon="/static/images/Langfristige-Vertraege.png"
            title="Keine langfristigen Verträge"
        />
        <Benfit
            icon="/static/images/Einfache-Online-Zahlung.png"
            title="Einfache Online Zahlung"
        />
    </div>

}