import React from 'react';
import PropTypes from 'prop-types';
import axios, { AxiosInstance } from "axios";

import AppContext from './AppContext';
import Calculator from './Calculator';

import './App.css';

declare var API_HOST_URL: any;

export default class App extends React.PureComponent implements React.ChildContextProvider<AppContext> {
  private api: AxiosInstance;

  static childContextTypes = {
    api: PropTypes.func
  }

  constructor(props: any) {
    super(props);

    this.api = axios.create({
      baseURL: API_HOST_URL,
      timeout: 15000,
      headers: {
        'Cache-Control': 'no-cache',
      }
    });
  }

  getChildContext(): AppContext {
    return {
      api: this.api
    }
  }

  render() {
    return (
      <Calculator />
    );
  }
}
