export enum Steps {
    Route = 1,
    Price = 2,
    Timetable = 3,
    Services = 4,
    Booking = 5,
    Payment = 6,
    Completed = 7
}

export const StepLabels = [
    '',
    'Route',
    'Price',
    'Timetable',
    'Services',
    'Booking',
    'Payment',
    'Completed'
]

export enum BookingSteps {
    Intro = "intro",
    Client = "client",
    Pickup = "pickup",
    Delivery = "delivery",
}