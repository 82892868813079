import React from 'react'
import { Guid } from "guid-typescript";
import { formatPrice } from "../util/format"

declare var paypal: any;

type Props = {
    onSelect: () => void,
}

export default class InvoiceCheckout extends React.PureComponent<Props> {
    
    render() {
        return (
            <div >
                <div className="invoice-button" onClick={this.props.onSelect}>Bezahlen auf Rechnung</div>
            </div>
        )
    }
}