import React from 'react'
import { Steps } from "../data/enums"

type Props = {
    previousStep?: Steps
    nextStep?: Steps
    nextLabel?: string
    isPreviousEnabled: boolean
    isNextEnabled: boolean
    onChangeStep: (step: Steps | undefined, isPrevious: boolean, isNext: boolean) => void
}

export default class StepNavigation extends React.PureComponent<Props> {
    render() {
        return (
            <div className="actions clearfix">
                <ul role="menu" aria-label="Pagination">
                    <li key="back" className={this.props.isPreviousEnabled ? "" : "disabled"} aria-disabled={!this.props.isPreviousEnabled}>
                        <a onClick={this.props.isPreviousEnabled ? this.setPreviousStep : undefined} role="menuitem">Zurück</a>
                    </li>
                    <li key="next" className={this.props.isNextEnabled ? "" : "disabled"} aria-disabled={!this.props.isNextEnabled}>
                        <a onClick={this.props.isNextEnabled ? this.setNextStep : undefined} role="menuitem">{this.props.nextLabel || "Weiter"}</a>
                    </li>
                </ul>
            </div>
        );
    }

    setPreviousStep = () => {
        this.props.onChangeStep(this.props.previousStep, true, false);
    }

    setNextStep = () => {
        this.props.onChangeStep(this.props.nextStep, false, true);
    }
}