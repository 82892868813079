import React from 'react'
import * as Formatter from "../util/format"

type Props = {
    value: number
}

export default class ExtraPrice extends React.PureComponent<Props>
{
    render() {
        if (this.props.value > 0) {
            let value = Number.isInteger(this.props.value) ? this.props.value : Formatter.formatReadablePrice(this.props.value)
            return (
                <span className="badge badge-success mt-0">+{value} EUR</span>
            );
        }
        else {
            return (
                <span className="badge badge-secondary mt-0">Kostenlos</span>
            );
        }
    }
}