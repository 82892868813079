import React from 'react'
import { Guid } from "guid-typescript";
import { formatPrice } from "../util/format"

declare var paypal: any;

type Props = {
    amount: number,
    onTransactionCompleted: (token: string) => void,
    onTransactionFailed: (error: any) => void,
}

export default class PayPalCheckout extends React.PureComponent<Props> {

    getSettings() {
        let refID = Guid.create().toString();
        let amount = formatPrice(this.props.amount);
        let onCompleted = this.props.onTransactionCompleted;
        let onError = this.props.onTransactionFailed;

        return {
            createOrder: function (data: any, actions: any) {
                return actions.order.create({
                    purchase_units: [{
                        reference_id: refID,
                        amount: {
                            currency_code: "EUR",
                            value: amount
                        }
                    }]
                });
            },
            onApprove: function (data: any, actions: any) {
                return actions.order.capture().then(function (details: any) {
                    onCompleted(details.purchase_units[0].payments.captures[0].id);
                });
            },
            onError: (error: any) => {
                onError(error);
            }
        }
    }

    componentDidMount() {
        this.refreshButtons();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.amount !== prevProps.amount) {
            this.refreshButtons();
        }
    }

    refreshButtons() {
        let settings = this.getSettings();
        paypal.Buttons(settings).render('#paypal-button-container');
    }

    render() {
        return (
            <div id="paypal-button-container"></div>
        )
    }
}