export function formatPrice(value: number) {
    return value.toFixed(2);
}

export function formatReadablePrice(value: number) {
    return new Intl.NumberFormat(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(value);
}

export function formatISODate(date: Date | undefined) {
    return date === undefined ? "" : date.toISOString();
}

export function formatReadableDate(date?: Date) {
    return date === undefined ? "" : date.toLocaleDateString();
}
