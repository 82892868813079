import React from 'react'
import PropTypes from 'prop-types';
import { AxiosInstance, AxiosResponse } from 'axios';
import classnames from 'classnames'
import { Key } from 'ts-key-enum';

declare var API_KEY_GEO: any;

interface Suggestion {
    Label: string;
    Longitude: number;
    Latitude: number;
}

type Props = {
    id: string
    value: string
    isValidValue?: boolean
    onCommitValue: (address: string, isSelectedAddress: boolean) => void
}

type State = {
    input: string
    cursor: number
    isSelectedAddress: boolean
    suggestions: string[]
}

type Context = {
    api: AxiosInstance
}

export default class AddressPicker extends React.PureComponent<Props, State, Context> {
    static contextTypes = {
        api: PropTypes.func
    }

    constructor(props: Props, context: Context) {
        super(props, context);
        this.state = {
            input: props.value,
            cursor: -1,
            isSelectedAddress: false,
            suggestions: [],
        }
    }

    render() {
        let classes = classnames(
            "form-control",
            {
                "is-valid": this.props.isValidValue === true,
                "is-invalid": this.props.isValidValue === false
            }
        );

        return (
            <div>
                <input
                    className={classes}
                    type="text"
                    required={true}
                    name={this.props.id}
                    value={this.state.input}
                    onChange={this.onChangeInput}
                    onKeyDown={this.onKeyDown}
                    onBlur={this.onBlurInput}
                />
                {this.state.suggestions && this.state.suggestions.length > 0 && this.state.isSelectedAddress === false && (
                    this.renderSuggestions()
                )}
            </div>
        );
    }

    renderSuggestions(): React.ReactNode {
        return (
            <div className="card">
                <div className="card-body">
                    <div className="">
                        <button type="button" className="close" aria-label="Schließen" onClick={this.onCloseSuggestions}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                        {this.state.suggestions.map((suggestion, index) => this.renderItem(suggestion, index))}
                    </div>
                </div>
            </div>
        );
    }

    renderItem(s: string, index: number): any {
        let classes = classnames(
            "dropdown-item",
            { "active": index === this.state.cursor },
        );
        return (
            <div
                key={`${s}`}
                className={classes}
                onClick={() => this.onSelectAddress(s)}
            >
                {s}
            </div>
        );
    }

    onSelectAddress(address: string): void {
        this.setState({
            input: address,
            isSelectedAddress: true
        });
        this.props.onCommitValue(address, true);
    }

    onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        let input = e.target.value;
        this.setState({
            input: input,
            isSelectedAddress: false
        });

        if (input.length > 5) {
            let api: AxiosInstance = this.context.api;
            api.get(`/geo/v1/geo/autocomplete?query=${input}`, {
                headers:{
                    "x-apikey": API_KEY_GEO
                }
            })
            .then((response: AxiosResponse) => {
                this.setState({ suggestions: response.data.data });
            });
        }
    }

    onKeyDown = (e: React.KeyboardEvent) => {
        const { cursor, suggestions } = this.state

        // arrow up/down button should select next/previous list element
        if (e.key === Key.ArrowDown && cursor < suggestions.length - 1) {
            this.setState(prevState => ({
                cursor: prevState.cursor + 1
            }))
        }
        else if (e.key === Key.ArrowUp && cursor > 0) {
            this.setState(prevState => ({
                cursor: prevState.cursor - 1
            }))
        }
        else if (e.key === Key.Enter && cursor >= 0) {
            let address = suggestions[cursor];
            this.onSelectAddress(address);
        }
    }

    onBlurInput = () => {
        let input = this.state.input;
        this.props.onCommitValue(input, this.state.isSelectedAddress);
    }

    onCloseSuggestions = () => {
        this.setState({ suggestions: [] });
    }
}