import i18nCountries from 'i18n-iso-countries'

import de from 'i18n-iso-countries/langs/de.json'

i18nCountries.registerLocale(de);

const names = i18nCountries.getNames("de");
 
let countries = Object.keys(names).map((code) => [code, names[code]]);



export default countries