import React from 'react'
import classnames from 'classnames'

type Props = {
    name: string
    value: string
    isSelected: boolean
    onSelect?: (name: string, value: string) => void
}

export default class Choice extends React.PureComponent<Props> {
    onSelect = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        this.props.onSelect?.(this.props.name, this.props.value);
    }

    render() {
        let classes = classnames(
            "card",
            this.props.name,
            this.props.value,
            { "selected": this.props.isSelected }
        );

        return (
            <div className="col-lg-6">
                <div className={classes} onClick={this.onSelect}>
                    <div className="card-body">
                        <div className="pricingTable1 text-center">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}